import TweenMax from 'TweenMax';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
export default class Footer {
  constructor() {
    // init controller
    var controller = new ScrollMagic.Controller();
    var timeline = new TimelineMax();

    let ballPosition =  [153,   208,  365,   549,  634,   923, 926,   75,   1203,  666,  1409, 839,  1570, 192,  1821, 602,  2158, -50];
    let lightPosition = [-2147, -741, -1935, -400, -1666, -26, -1374, -874, -1097, -283, -891, -110, -730, -757, -479, -347, -142, -3000];

    let ball = [];
    let light = [];

    ballPosition.forEach((e, i) => {
      if (i % 2 == 0) {
        ball[i] = TweenMax.to('.footer__pipeball', 0.5, {
          top: ballPosition[i],
        });
      } else {
        ball[i] = TweenMax.to('.footer__pipeball', 0.5, {
          right: ballPosition[i],
        });
      }
    });

    lightPosition.forEach((e, i) => {
      if (i % 2 == 0) {
        light[i] = TweenMax.to('.footer__light', 0.5, {
          top: lightPosition[i],
        });
      } else {
        if (i == lightPosition.length-1) {
          light[i] = TweenMax.to('.footer__light', 0.5, {
            right: lightPosition[i],
            opacity: 0,
          });
        } else {
          light[i] = TweenMax.to('.footer__light', 0.5, {
            right: lightPosition[i],
          });
        }
      }
    });

    var fade = TweenMax.to('.go-the-distance', 0.5, {
      opacity: 1,
    });

    timeline.add([light[17], fade]);

    let scenes = {
      offset:   [300, 500, 600, 700, 800, 900, 1000, 1100, 1300, 1400, 1600, 1650, 1700, 1750, 1850, 1950, 2050, 2225],
      duration: [200, 100, 100, 100, 100, 100, 100,  200,  100,  200,  50,   50,   50,   100,  100,  100,  200,  75]
    };

    scenes.offset.forEach((e, i) => {
      new ScrollMagic.Scene({
        triggerElement: '.footer__easteregg',
        triggerHook: 1,
        offset: scenes.offset[i],
        duration: scenes.duration[i],
      })
      .reverse(false)
      .setTween(ball[i])
      .addTo(controller);

      if (i == scenes.offset.length -1) {
        new ScrollMagic.Scene({
          triggerElement: '.footer__easteregg',
          triggerHook: 1,
          offset: scenes.offset[i],
          duration: scenes.duration[i],
        })
        .reverse(false)
        .setTween(timeline)
        .addTo(controller);
      } else {
        new ScrollMagic.Scene({
          triggerElement: '.footer__easteregg',
          triggerHook: 1,
          offset: scenes.offset[i],
          duration: scenes.duration[i],
        })
        .reverse(false)
        .setTween(light[i])
        .addTo(controller);
      }
    });
  }
}
