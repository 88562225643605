export default class hero {
  constructor() {
    this.hero = $('.hero__image');

    if (this.hero.length > 0) {
      $(window).scroll(() => {
        if ($(window).width() > 1024 && !this.hero.hasClass('hero__image--cutout')) {
          let scrollPosition = window.pageYOffset;
          let limit = this.hero.offset().top + this.hero.outerHeight();
          if (scrollPosition > this.hero.offset().top && scrollPosition <= limit) {
            if (scrollPosition > (this.hero.offset().top + 100)) {
              this.hero.css({ backgroundPositionY: (50 - 60 * scrollPosition / limit) + '%' });
            } else if ((scrollPosition > this.hero.offset().top + 50)) {
              this.hero.css({ backgroundPositionY: (50 - 30 * scrollPosition / limit) + '%' });
            } else {
              this.hero.css({ backgroundPositionY: (50 - 10 * scrollPosition / limit) + '%' });
            }
          } else {
            this.hero.css({ backgroundPositionY: '50%' });
          }
        }
      });
    }

    if ($('body').hasClass('home') && $('.hero').length > 0) {
      $(".hero__heading:contains('Distance')").html(function(_, html) {
        return html.replace(/(Distance)/g, '<span class="blue">$1</span>');
      });
      $(".hero__excerpt:contains('Distance')").html(function(_, html) {
        return html.replace(/(Distance)/g, '<span class="blue">$1</span>');
      });
    }
  }
}
