export default class Gallery {
  constructor() {
    this.prevArrow = `
      <button type="button" class="gallery__nav gallery__prev">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" class="arrow">
          <path d="M18.4,12.482A1.5,1.5,0,0,1,20.6,14.518L16.926,18.5H26.5a1.5,1.5,0,0,1,0,3H16.925L20.6,25.482A1.5,1.5,0,0,1,18.4,27.518L11.459,20Z" fill="#00aeef"></path><path d="M0,20A20,20,0,1,1,20,40,20.023,20.023,0,0,1,0,20Zm3,0A17,17,0,1,0,20,3,17.019,17.019,0,0,0,3,20Z" fill="#00aeef"></path>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" class="arrow--filled">
          <path d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm6.5,21.5H16.925L20.6,25.482a1.5,1.5,0,0,1-2.2,2.036L11.459,20,18.4,12.482a1.5,1.5,0,0,1,2.2,2.036L16.926,18.5H26.5a1.5,1.5,0,0,1,0,3Z" fill="#00aeef"></path>
        </svg>
      </button>
    `;

    this.nextArrow = `
      <button type="button" class="gallery__nav gallery__next">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" class="arrow">
          <path d="M21.1,12.982A1.5,1.5,0,0,0,18.9,15.018L22.574,19H13a1.5,1.5,0,0,0,0,3h9.575L18.9,25.982A1.5,1.5,0,0,0,21.1,28.018L28.041,20.5Z" transform="translate(-0.5 -0.5)" fill="#00aeef"></path><path d="M20.5,40.5a20,20,0,1,1,20-20A20.023,20.023,0,0,1,20.5,40.5Zm0-37a17,17,0,1,0,17,17A17.019,17.019,0,0,0,20.5,3.5Z" transform="translate(-0.5 -0.5)" fill="#00aeef"></path>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" class="arrow--filled">
          <path d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm.6,27.518a1.5,1.5,0,0,1-2.2-2.036L22.075,21.5H12.5a1.5,1.5,0,0,1,0-3h9.574L18.4,14.518a1.5,1.5,0,0,1,2.2-2.036L27.541,20Z" fill="#00aeef"></path>
        </svg>
      </button>
    `;
  }

  initGalleries() {
    $('.gallery__inner').each((index, gallery) => {
      const galleryContainer = $(gallery);
      const parent = galleryContainer.parent();

      galleryContainer.slick({
        adaptiveHeight: true,
        loop: true,
        dots: true,
        appendDots: parent,
        prevArrow: this.prevArrow,
        nextArrow: this.nextArrow
      });
    });

    $('.case-study-hero__gallery--inner').each((index, gallery) => {
      const galleryContainer = $(gallery);
      const parent = galleryContainer.parent();
      const controls = parent.find('.case-study-hero__controls');

      galleryContainer.slick({
        adaptiveHeight: true,
        loop: true,
        arrows: false,
        dots: true,
        appendDots: controls,
      });

      controls.find('.button-prev').on('click', () => {
        galleryContainer.slick('slickPrev');
      });

      controls.find('.button-next').on('click', () => {
        galleryContainer.slick('slickNext');
      });
    });
  }
}
