export default class WysiwygExtend {
  constructor() {
    this.elementWrapper = '<div class="button-container">';
    this.elementBackground = '<div class="button__background"><svg id="f779a0e2-69ed-4020-b22a-f45a1564f46d" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 29"><title>bluebutton_background_right</title><polygon class="a7a2700b-93d2-47a4-aa3d-5718f721bcce" points="26 0 0 0 0 1.065 0 2.079 0 25.92 0 26.488 0 29 26 0" fill="#00aeef"/></svg></div>';
    this.svgBorder = '<svg id="ab03f62e-9e63-4a6d-8c12-15d6fd29ff59" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 29"><title>bluebutton_border_right</title><polygon class="ba427ffd-6a77-461a-ac2e-2700450f3641" points="0 29 0.876 29 26 0 0 0 0 2.079 21.53 2.079 0.008 26.921 0 26.921 0 29" fill="#00aeef"/></svg>';

    this.buttons = $('.button-wysiwyg');
    this.buttons.each((index, e) => {
      this.content = $(e).text();
      this.buttonNoCopy = $(e).text('');
      this.spanner = '<span>' + this.content + '</span>'
      this.buttonNoCopy.wrap(this.elementWrapper).append(this.elementBackground).append(this.spanner).append(this.svgBorder).css('opacity', '1');
    });
  }
}