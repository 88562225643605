export default class images {
  constructor() {
    this.news_content = $('.news-show__content');

    if (this.news_content.length > 0) {
      alternate();
    }
  }
}

function alternate() {
  var img = $('.news-show__content > .image');
  let images = $.makeArray(img);
  images.forEach((element, index) => {
    if (index % 2 == 0) {
      $(element).addClass('image-left');
    } else {
      $(element).addClass('image-right');
    }
  });
}