export default class Stats {
  constructor() {
    this.stats = $('.product-stats__statistic-value');
    this.statsLength = this.stats.length;

    $(window).on('load resize onorientationchange', () => {
      this.stats.css('font-size', 120);
      this.completed = 0;

      this.stats.each((index, stat) => {
        this.fitChild($(stat));
      });
    });
  }

  fitChild(target) {
    const targetWidth = target.width(),
      parentWidth = target.parent().width();

    if (targetWidth > parentWidth) {
      const currentFontSize = parseInt(target.css('font-size'), 10);
      target.css('font-size', currentFontSize - 2);

      this.fitChild(target);
    } else {
      this.completed++;

      if (this.completed === this.statsLength) {
        this.equalizeFontSizes();
      }
    }
  }

  equalizeFontSizes() {
    let fontSizeArray = [];

    this.stats.each((index, stat) => {
      fontSizeArray.push(parseInt(stat.style.fontSize, 10));
    });

    const minFontSize = Math.min(...fontSizeArray);
    this.stats.css('font-size', minFontSize);
  }
}