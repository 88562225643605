export default class Slider {
  constructor() {
    this.slidesContainer = $('.featured-slides__container');
    this.slides = $('.featured-slide');

    this.slideDots = $('.slide-dot');

    this.slides.find('.featured-slide__prev').on('click', (e) => {
      const currentSlide = $(e.currentTarget || e.target).parent();
      this.prevSlide(currentSlide);
    });

    this.slides.find('.featured-slide__next').on('click', (e) => {
      const currentSlide = $(e.currentTarget || e.target).parent();
      this.nextSlide(currentSlide);
    });

    this.slideDots.find('button').on('click', (e) => {
      const dot = $(e.currentTarget || e.target).parent();
      const dotIndex = dot.index();

      this.selectSlide(dotIndex);
    });

    $(window).on('load resize', () => {
      const overlayTriangle = $('.white-diagonal');
      const overlayTriangleWidth = overlayTriangle.width();

      const overlay = overlayTriangle.parent();

      overlay.css({
        width: $(window).width() + overlayTriangleWidth,
        right: -overlayTriangleWidth
      });
    });
  }

  prevSlide(slide) {
    const prevIndex = slide.index() - 1;
    const prev = this.slides[prevIndex];

    $(slide).find('button').attr('tabindex', -1);
    $(prev).find('button').removeAttr('tabindex');

    $(slide).removeClass('featured-slide--active');
    $(prev).addClass('featured-slide--active');

    if (prevIndex === 0) {
      this.slidesContainer.css({ transform: 'translateX(0)' });

      $('.featured-slide')
        .find('.featured-slide__prev')
        .removeClass('featured-slide__prev--visible');
    } else {
      this.slidesContainer.css({ transform: `translateX(-${prevIndex}00%)` });
    }

    $('.featured-slide')
      .find('.featured-slide__next')
      .addClass('featured-slide__next--visible');

    this.setActiveDot(prevIndex);
  }

  nextSlide(slide) {
    const nextIndex = slide.index() + 1;
    const next = this.slides[nextIndex];

    $(slide).find('button').attr('tabindex', -1);
    $(next).find('button').removeAttr('tabindex');

    $(slide).removeClass('featured-slide--active');
    $(next).addClass('featured-slide--active');

    this.slidesContainer.css({ transform: `translateX(-${nextIndex}00%)` });

    if (nextIndex > 0) {
      $('.featured-slide')
        .find('.featured-slide__prev')
        .addClass('featured-slide__prev--visible');
    }

    if (nextIndex === this.slides.length - 1) {
      $('.featured-slide')
        .find('.featured-slide__next')
        .removeClass('featured-slide__next--visible');
    } else {
      $('.featured-slide')
        .find('.featured-slide__next')
        .addClass('featured-slide__next--visible');
    }

    this.setActiveDot(nextIndex);
  }

  selectSlide(slideIndex) {
    const selectedSlide = $(this.slides[slideIndex]);
    const activeSlide = $('.featured-slide--active');

    activeSlide.find('button').attr('tabindex', -1);
    selectedSlide.find('button').removeAttr('tabindex');

    activeSlide.removeClass('featured-slide--active');
    selectedSlide.addClass('featured-slide--active');

    if (slideIndex === 0) {
      this.slidesContainer.css({ transform: 'translateX(0)' });

      $('.featured-slide')
        .find('.featured-slide__prev')
        .removeClass('featured-slide__prev--visible');
    } else {
      this.slidesContainer.css({ transform: `translateX(-${slideIndex}00%)` });

      $('.featured-slide')
        .find('.featured-slide__prev')
        .addClass('featured-slide__prev--visible');
    }

    if (slideIndex === this.slides.length - 1) {
      $('.featured-slide')
        .find('.featured-slide__next')
        .removeClass('featured-slide__next--visible');
    } else {
      $('.featured-slide')
        .find('.featured-slide__next')
        .addClass('featured-slide__next--visible');
    }

    this.setActiveDot(slideIndex);
  }

  setActiveDot(activeIndex) {
    $('.slide-dot--active').removeClass('slide-dot--active');
    this.slideDots.eq(activeIndex).addClass('slide-dot--active');
  }
}
