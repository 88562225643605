import '@babel/polyfill'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';
import objectFitImages from 'object-fit-images';

objectFitImages();

import Categories from './categories';
import Dealers from './dealers';
import EquipmentMenu from './equipment-menu';
import Features from './features';
import Footer from './footer';
import Forms from './forms';
import Gallery from './gallery';
import GlobalAlert from './global-alert';
import Header from './header';
import Hero from './hero';
import Images from './images';
import Slider from './slider';
import Stats from './stats';
import Tabs from './tabs';
import Video from './video';
import WysiwygExtend from './wysiwyg-extend';
import Measurement from './measurement';

new Categories();
new Dealers();
new EquipmentMenu();
new Features();
new Footer();
new Forms();
new GlobalAlert();
new Header();
new Hero();
new Images();
new Slider();
new Stats();
new Tabs();
new Video();
new WysiwygExtend();
new Measurement();

const galleries = new Gallery();
galleries.initGalleries();
