export default class Video {
  constructor() {
    this.playButton = $('.video__cover');

    this.playButton.on('click', (e) => {
      const target = $(e.currentTarget || e.target);
      this.playVideo(target);
    })
  }

  playVideo(target) {
    const video = target.siblings('video');
    const iframe = target.siblings('iframe');

    target.fadeOut(600);

    if (iframe.length) {
      iframe[0].src += '&autoplay=1';
    } else {
      video[0].play();
    }
  }
}