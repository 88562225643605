export default class Measurement {
  constructor() {
    $(window).on('load', () => {
      let inspection = [...$('.product-stats__statistic-value span')];
      let regex = new RegExp('[\",\']', 'g');

      inspection.forEach((e) => {
        if (regex.test($(e).text())) {
          $(e).html(smallerizer($(e).text(), regex));
        }
      });
    });
  }
}

function smallerizer(quotation, regex) {
  return quotation.replace(regex, (e) => {
    return `<span class="smallerized">${e}</span>`
  });
}