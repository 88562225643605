import Cookies from 'js-cookie';
export default class GlobalAlert {
  constructor() {
    this.alertCookie = Cookies.get('cues-alert');
    this.close = $('.global-alert__close');
    this.sessionData = $('.global-alert').data('session');
    this.link = $('.alert-link');

    this.close.on('click', (e) => {
      this.closeAlert();
    });

    if (this.alertCookie && (this.alertCookie !== this.sessionData)) {
      this.removeAlert();
    } else if (!this.alertCookie) {
      this.showAlert();
    }

    this.link.on('click', (e) => {
      this.redirect()
    });
  }

  closeAlert() {
    Cookies.set('cues-alert', this.sessionData, { expires: 1 });
    $('.global-alert').removeClass('global-alert--display');
  }

  removeAlert() {
    Cookies.remove('cues-alert');
    $('.global-alert').addClass('global-alert--display');
  }

  showAlert() {
    $('.global-alert').addClass('global-alert--display');
  }

  redirect() {
    Cookies.set('cues-alert', $('.global-alert').data('session'), { expires: 1 });
  }
}