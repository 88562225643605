export default class Categories {
  constructor() {
    this.categoryLinks = $('.category-link');
    this.productToggle = $('.expand-products');
    this.productLinks = $('.product-links:not(.no-category)');

    this.subcategoryToggle = $('.subcategory > a, .subcategory > a .expand-products');
    this.subcategories = $('.subcategory');
    this.subproducts = $('.subproducts');

    this.equipmentToggle = $('.equipment-submenu-toggle');
    this.equipmentSubmenu = $('.products__categories-list');

    this.productToggle.on('click', (e) => {
      const target = $(e.currentTarget || e.target);
      this.toggleProducts(target);
    });

    this.subcategoryToggle.on('click', (e) => {
      let target = $(e.currentTarget || e.target);
      if (target.hasClass('expand-products')) {
        target = target.parent();
      }
      this.toggleSubcategory(target);
    });

    this.equipmentToggle.on('click', (e) => {
      if (this.equipmentToggle.hasClass('equipment-submenu-toggle--open')) {
        this.equipmentToggle.removeClass('equipment-submenu-toggle--open');
        this.equipmentToggle.attr('aria-label', 'Open Category Menu');
      } else {
        this.equipmentToggle.addClass('equipment-submenu-toggle--open');
        this.equipmentToggle.attr('aria-label', 'Close Category Menu');
      }

      this.equipmentSubmenu.stop().slideToggle(600);
    });
  }

  toggleProducts(target) {
    if (target.data('category')) {
      const parent = target.parent('.category-link');
      const products = target.next();

      if (parent.hasClass('category-link--open')) {
        parent.removeClass('category-link--open');
        products.stop().slideUp(400);

        target.attr('aria-label', `See all ${target.data('category')} products`)
      } else {
        this.categoryLinks.removeClass('category-link--open');
        this.productLinks.stop().slideUp(400);

        parent.addClass('category-link--open');
        products.stop().slideDown(400);

        target.attr('aria-label', `Close ${target.data('category')} category`)
      }
    }
  }

  toggleSubcategory(target) {
    const parent = target.parent('.subcategory');
    const subs = target.next();

    if (parent.hasClass('subcategory--active')) {
      parent.removeClass('subcategory--active');
      subs.stop().slideUp(400);

      target.attr('aria-label', `See all ${target.data('subcategory')} products`);
    } else {
      this.subcategories.removeClass('subcategory--active');
      this.subproducts.stop().slideUp(400);

      parent.addClass('subcategory--active');
      subs.stop().slideDown(400);

      target.attr('aria-label', `See all ${target.data('subcategory')} products`);
    }
  }
}