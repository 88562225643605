export default class Features {
  constructor() {
    this.slideData = [];
    this.features = $('.product-features');

    this.features.each((index, element) => {
      const featuresLength = $(element).find('.product-features__feature').length

      this.slideData.push({
        featuresLength: featuresLength,
        slides: element,
        breakpoint: this.getBreakpoint(featuresLength),
        slider: ''
      });
    });

    $(document).ready(() => {
      this.initFeaturedSliders();
    });

    $(window).on('resize', () => {
      this.reslick();
    });
  }

  initFeaturedSliders() {
    this.slideData.forEach((data) => {
      data.prevButton = $(data.slides).find('.product-features__prev');
      data.nextButton = $(data.slides).find('.product-features__next');

      let responsiveOptions = [
        {
          breakpoint: 1380,
          settings: 'unslick'
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2
          }
        }
      ];

      let unslickBreakPoint = responsiveOptions.find(b => {
        return b.breakpoint === data.breakpoint;
      })

      unslickBreakPoint.settings = 'unslick';

      responsiveOptions = responsiveOptions.filter(b => {
        return b.breakpoint <= data.breakpoint;
      });

      data.responsiveOptions = responsiveOptions;

      data.slider = $('.product-features__slides').slick({
        slidesToScroll: 1,
        slidesToShow: 1,
        prevArrow: data.prevButton,
        nextArrow: data.nextButton,
        mobileFirst: true,
        responsive: responsiveOptions
      });
    });
  }

  reslick() {
    const $windowWidth = $(window).width();

    this.slideData.forEach((data) => {
      if ($windowWidth < data.breakpoint && !data.slider.hasClass('slick-initialized')) {
        data.slider = $('.product-features__slides').slick({
          slidesToScroll: 1,
          slidesToShow: 1,
          prevArrow: data.prevButton,
          nextArrow: data.nextButton,
          mobileFirst: true,
          responsive: data.responsiveOptions
        });
      }
    });
  }

  getBreakpoint(length) {
    if (length === 2) {
      return 680;
    } else if (length === 3) {
      return 960;
    } else if (length === 4) {
      return 1100;
    } else if (length > 4) {
      return 1380;
    } else {
      return undefined;
    }
  }
}
